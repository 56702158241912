import React from 'react';
import { QRCode } from 'react-qrcode-logo';

import logo from '../../assets/min-logo.svg';

const PrintQR = (p) => {


  const link = `https://escambo.app/Pagar/${p.match.params.link}`

    return (
      <div className="main-wide-container">
        <QRCode value={link} logoImage={logo} logoWidth={240} size={600}/>
      </div>
    );
}

export default PrintQR;