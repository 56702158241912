import React, { useEffect, useState, useContext } from 'react';

import TableExtrato from '../../layout/TableExtrato';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Extrato = (p) => {

    const {loggedUser: user} = useContext(EscamboContext);
    const [permutas, setPermutas] = useState([]);

    //buscar produtos
    async function loadPermutas() {

        const response = await api.get('/Permuta/listar');
        setPermutas(response.data);
    }

    //atualizar saldo
    async function atualizarSaldo() {

        const id = user._id;

        api.get(`/Usuario/find/${id}`).then((response, err) => {

            if (response.status === 200) {
                   
                p.app.setCookie('loggedUser', response.data);        
            } else {
                const error = new Error(response.error);
                throw error;
            }

        }).catch((err) => {
            console.error(err);
        });
    }

    //carregar usuario
    useEffect(() => {

        if (!user) {
            p.app.alterState('whereTo',p.history.location.pathname);
            p.history.push('/');
        };
    });

    //carregar permutas
    useEffect(() => {

        if (user._id !== '') {
          loadPermutas();
          atualizarSaldo();
        }
    // eslint-disable-next-line
    }, [user._id]);

    return (
        <div>
            
            <div className="main-container" >

                <div className="container">
                    
                    <div className="row">
                        <div className="col-md mb-3" style={{textAlign: "center"}}>
                            <h2 className="main-title mb-1">Extrato Movimentação</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TableExtrato
                                rows={permutas}
                                user={user}
                                history={p.history}
                            />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Extrato;