import React, { useEffect, useState, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { components } from "react-select";
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import SendMoneyIcon from '../../assets/sendmoney.png';

import TableProdutos from '../../layout/TableProdutos';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#003459',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    solicitarPermuta: {
        position: 'fixed', 
        bottom: 40,
        right: 0,
        zIndex: 999,
        height: 75
    },
    iconPermuta: {
        color: '#00A8E8',
        position: 'relative', 
        cursor: 'pointer',
        animation: `$bounce 1s infinite linear`,
        fontSize: 50,
        height: 100,
        width: 100
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#00A8E8',
        margin: 2,
        '&:hover': {
            background: '#007EA7',
         },
    },
    "@keyframes bounce": {
        "0%": {
            bottom: 0
        },
        "50%": {
            bottom: '-0.2em'
        },
        "70%": {
            bottom: '-0.3em'
        },
        "100%": {
            bottom: 0
        }
      },
  }));
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PermutaDialog = (p) => {

    const {loggedUser, parceiros} = useContext(EscamboContext);
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [openPermuta, setOpenPermuta] = useState(false);
    const [openTransf, setOpenTransf] = useState(false);

    const [oferta, setOferta] = useState({ nome: '', valor: 0.00 });
    const [permuta, setPermuta] = useState({ valor: 0.00, produtos: [] });
    const [clienteOptions] = useState([]);

    //atualiza dados da oferta
    const modifyOferta = e => {
        setOferta({
            ...oferta,
            [e.target.name]: e.target.value,
        });
    }
    
    //atualiza dados da permuta
    const modifyPermuta = e => {

        if (e === 'addOferta') {
            
            let idOferta = permuta.produtos.length+1;

            oferta.id = idOferta;

            permuta.produtos.push(oferta);

            setOferta({
                nome: '',
                id: '',
                valor: ''
            });
        } else {        
            setPermuta({
                ...permuta,
                [e.target.name]: e.target.value,
            });
        }
    }

    //Carregar clientes para popular lista de sugestões
    useEffect(() => {
        function loadParceiros() {
            api.get('/Usuario/listar').then((response) => {
                p.app.alterState('parceiros',response.data);
            }).catch((err) => {
                console.error(err);
            });
        }

        function feedClientesOptions(parceiros) {

            clienteOptions.splice(0, clienteOptions.length);

            for (let i = 0; i < parceiros.length; i++) {

                if (parceiros[i]._id !== loggedUser._id) {
                    
                    let option = {
                        value: parceiros[i].nome,
                        id: parceiros[i]._id,
                        label: parceiros[i].nome
                    };

                    clienteOptions.push(option);
                }
            }
        };

        if (parceiros && clienteOptions.length === 0) {
            feedClientesOptions(parceiros.sort((a, b) => a.nome > b.nome ? 1 : -1));
        } else if (clienteOptions.length === 0) {
            loadParceiros();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parceiros]);

    async function handleFornecChange(e) {

        if (e) {

            setPermuta({
                ...permuta,
                fornecedor: e.id,
            });
        } else {

            setPermuta({
                produtos: []
            });
        }
    };

    async function handleClienteChange(e) {

        if (e) {

            setPermuta({
                ...permuta,
                cliente: e.id,
            });
        } else {

            setPermuta({
                produtos: []
            });
        }
    };

    const SingleClienteValue = props => (
        <components.SingleValue {...props}>
            {props.data.label}
        </components.SingleValue>
    );

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenTransf = () => {
        handleCloseDialog();
        setOpenTransf(true);
    };

    const handleCloseTransf = () => {
        setOpenTransf(false);
    };

    const handleOpenPermuta = () => {
        handleCloseDialog();
        setOpenPermuta(true);
    };

    const handleClosePermuta = () => {
        setOpenPermuta(false);
    };

    async function enviarSolicitacaoPermuta(e) {

        e.preventDefault();

        if (!permuta.cliente) {
            p.app.notify('warning', 'Escolha um cliente', 5);
            return;
        }

        if (permuta.produtos.length<=0) {
            p.app.notify('warning', 'Você deve adicionar pelo menos um produto/serviço', 5);
            return;
        }

        permuta.fornecedor = loggedUser._id;
        permuta.produtos.map((produto) => 
            produto.valor = parseFloat(produto.valor.replace('R$ ','').replace('.','').replace(',','.'))
        )
        permuta.valor = permuta.produtos.reduce((sum, produto) => {
            return sum + produto.valor
        }, 0);

        api.post('/Permuta/cadastrar', {permuta}).then((response) => {

            if (response.status === 200) {
                p.app.notify('success', 'Solicitação de permuta enviada com sucesso!', 2);
                setPermuta({
                    produtos: []
                });
                handleClosePermuta();
            } else {
                p.app.notify('danger', response.data, 2);
            }
        }).catch((err) => {
            console.error(err);
            if (err.response.data) { p.app.notify('danger', err.response.data, 2); };
        });
    }

    async function transferirCredito(e) {

        e.preventDefault();

        if (!permuta.fornecedor) {
            p.app.notify('warning', 'Escolha um fornecedor', 5);
            return;
        }

        if (!permuta.valor || permuta.valor <= 0) {
          p.app.notify('warning', 'Coloque um valor maior que zero', 5);
          return;
        }

        permuta.cliente = loggedUser._id;
        permuta.confirmada = true;
        permuta.valor = parseFloat(permuta.valor.replace('R$ ','').replace('.','').replace(',','.'))

        api.post('/Permuta/cadastrar', {permuta}).then((response) => {

            if (response.status === 200) {
                p.app.notify('success', 'Créditos transferidos com sucesso!', 2);
                handleCloseTransf();
            } else {
                p.app.notify('danger', response.data, 2);
            }
        }).catch((err) => {
            console.error(err);
            if (err.response.data) { p.app.notify('danger', err.response.data, 2); };
        });

        setPermuta({
            valor: 0.00,
            produtos: []
        });
    }


    return (            
      <div className="main-wide-container" >

          <div className={classes.solicitarPermuta}>
             <img src={SendMoneyIcon} 
                  alt='Transferir'
                  className={classes.iconPermuta}
                  onClick={handleOpenDialog}
              />
          </div>

          <Dialog 
            open={openDialog}
            onClose={handleCloseDialog} 
            fullWidth
            aria-labelledby="simple-dialog-title" 
          >
            <DialogTitle id="simple-dialog-title">O que deseja fazer?</DialogTitle>
            <div>
                <div className="col-md mb-2">
                    <button
                        type="button"
                        className="btn btn-primary btn-lg btn-block btn-save-inst"
                        onClick={handleOpenTransf}
                    >Transferir Crédito</button>
                </div>
            </div>
            <div>
                <div className="col-md mb-2">
                    <button
                        type="button"
                        className="btn btn-primary btn-lg btn-block btn-save-inst"
                        onClick={handleOpenPermuta}
                    >Solicitar Permuta</button>
                </div>
            </div>
          </Dialog>

          <Dialog fullScreen open={openTransf} onClose={handleCloseTransf} TransitionComponent={Transition}>
            <form className="needs-validation" noValidate="" onSubmit={transferirCredito}>
              <AppBar className={classes.appBar}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleCloseTransf} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                      Transferência de Crédito
                  </Typography>
                  <Button className={classes.button} type={"submit"}>
                      Transferir
                  </Button>
              </Toolbar>
              </AppBar>
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-md-9 mt-2">
                          <label htmlFor="cliente">Cliente</label>
                          <Typography name="cliente" variant="h6" className={classes.title}>
                              {loggedUser.nome}
                          </Typography>
                      </div>
                      <div className="col-12 col-md-3 mt-2">
                          <label htmlFor="valor">Valor</label>
                          {/* <input 
                              type="number"
                              className="form-control"
                              name="valor"
                              value={permuta.valor || ''}
                              onChange={modifyPermuta}
                              required
                          /> */}
                          <NumberFormat 
                              className="form-control"
                              displayType={'input'} 
                              name="valor"
                              thousandSeparator={'.'} 
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={2}
                              prefix={'R$ '} 
                              value={permuta.valor || 0.00} 
                              onChange={modifyPermuta}
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col mt-2">
                          <label htmlFor="nomeFornecedor">Fornecedor</label>
                          <Select
                              isClearable
                              name="nomeFornecedor"
                              id="idFornecedor"
                              options={clienteOptions}
                              onChange={handleFornecChange}
                              placeholder="Escolha um fornecedor..."
                              components={{ SingleClienteValue }}
                              required
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 mt-2" style={{textAlign: "center"}}>
                          <label htmlFor="observacoes">Observações</label>
                          <textarea 
                              style={{width: "100%", height: "100px"}}
                              name="observacoes"
                              value={permuta.observacoes || ''}
                              placeholder={"Descreva o produto ou serviço adquirido."}
                              onChange={modifyPermuta}
                              required
                          />
                      </div>
                  </div>
              </div>
            </form>
          </Dialog>

          <Dialog fullScreen open={openPermuta} onClose={handleClosePermuta} TransitionComponent={Transition}>
            <form className="needs-validation" noValidate="" onSubmit={enviarSolicitacaoPermuta}>
              <AppBar className={classes.appBar}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleClosePermuta} aria-label="close">
                  <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                      Solicitação de Permuta
                  </Typography>
                  <Button className={classes.button} type={"submit"}>
                      SOLICITAR
                  </Button>
              </Toolbar>
              </AppBar>
              <div className="container">
                  <div className="row">
                      <div className="col mt-2">
                          <label htmlFor="fornecedor">Fornecedor</label>
                          <Typography name="fornecedor" variant="h6" className={classes.title}>
                              {loggedUser.nome}
                          </Typography>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col mt-2">
                          <label htmlFor="nomeCliente">Cliente</label>
                          <Select
                              isClearable
                              name="nomeCliente"
                              id="idCliente"
                              options={clienteOptions}
                              onChange={handleClienteChange}
                              placeholder="Escolha um cliente..."
                              components={{ SingleClienteValue }}
                              required
                          />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12 col-md-8 mt-2">
                          <label htmlFor="descricao">Descrição do Produto/Serviço</label>
                          <input
                              type="text"
                              className="form-control"
                              name="nome"
                              placeholder="Descreva o produto ou serviço fornecido"
                              value={oferta.nome || ''}
                              onChange={modifyOferta}
                          />
                      </div>
                      <div className="col-8 col-md-3 mt-2">
                          <label htmlFor="valor">Valor</label>
                          {/* <input 
                              type="number"
                              className="form-control"
                              name="valor"
                              value={oferta.valor || ''}
                              onChange={modifyOferta}
                          /> */}
                          <NumberFormat 
                              className="form-control"
                              displayType={'input'} 
                              name="valor"
                              thousandSeparator={'.'} 
                              decimalSeparator={','}
                              fixedDecimalScale
                              decimalScale={2}
                              prefix={'R$ '} 
                              value={oferta.valor || 0.00} 
                              onChange={modifyOferta}
                          />
                      </div>
                      <div className="col-4 col-md-1 mt-2">
                          <br/>
                          <AddCircleIcon 
                              value="addOferta"
                              style={{
                                  color: '#00A8E8',
                                  fontSize: 50,
                                  cursor: 'pointer'
                              }}
                              onClick={() => modifyPermuta("addOferta")}
                          />
                      </div>
                      <div className="col-12 mt-2">
                          <TableProdutos
                              rows={permuta.produtos}
                              // delRow={delOferta}
                              title={"Podutos/Serviços fornecidos:"}
                              temValor={true}
                          />
                      </div>
                      <div className="col-12 mt-2" style={{textAlign: "center"}}>
                          <label htmlFor="observacoes">Observações</label>
                          <textarea 
                              style={{width: "100%", height: "100px"}}
                              name="observacoes"
                              value={permuta.observacoes || ''}
                              onChange={modifyPermuta}
                          />
                      </div>
                  </div>
              </div>
            </form>
          </Dialog>

      </div>
    );
}

export default PermutaDialog;