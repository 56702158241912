import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import NumberFormat from 'react-number-format';
import PrintIcon from '@material-ui/icons/Print';
import Typography from '@material-ui/core/Typography';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: será calculada com base em palette.primary.main,
      main: '#003459',
      // dark: será calculada com base em palette.primary.main,
      // contrastText: será calculada para contrastar com palette.primary.main
    },
    secondary: {
      light: '#00A8E8',
      main: '#007EA7',
      // dark: será calculada com base palette.secondary.main,
      contrastText: '#FFFFFF',
    },
    // Usado por `getContrastText()` para maximizar o contraste entre
    // o plano de fundo e o texto.
    contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2,
  },
}, ptBR);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  // { id: 'data', numeric: false, disablePadding: true, label: 'Data' },
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'imprimir', numeric: true, disablePadding: false, label: '' },
];


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  container: {
    maxHeight: 350,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 330,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  debito: {
    color: 'red',
  },
  credito: {
    color: 'green',
  },
}));

// export default function EnhancedTable() {
const EnhancedTable = (p) => {

  const rows = p.rows;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nome');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function printComprovante(permuta) {
    p.history.push({
      pathname: `/comprovante/${permuta._id}`,
      state: { permuta: permuta }
    })
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  getSorting(order, orderBy))
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const ehTransf = (row.cliente._id === p.user._id) ? true : false;

                    return (
                      (row.confirmada) ?
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={(row._id) ? row._id : row.id}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="default"
                          >
                            <NumberFormat
                              className={(ehTransf) ? classes.debito : classes.credito}
                              displayType={'text'}
                              name="valor"
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={(ehTransf) ? '-R$ ' : '+R$ '}
                              value={row.valor}
                            />

                            <br />

                            {(ehTransf)
                              ? 'Transferência para ' + row.fornecedor.nome
                              : 'Recebido de ' + row.cliente.nome
                            }

                            <br />

                            {(row.comissao > 0 && !row.comissaoPaga) && (
                              <>
                                <Typography variant="caption" style={{ color: "rgb(215 130 4)" }}>
                                  Pendente de confirmação da outra parte
                                </Typography>
                                <br/>
                              </>
                            )}

                            {new Intl.DateTimeFormat("pt-BR",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit"
                              }).format(new Date(row.createdAt))
                            }

                          </TableCell>

                          <TableCell align="right">
                            {!(row.comissao > 0 && !row.comissaoPaga) && (
                              <PrintIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => printComprovante(row)}
                              />
                            )}
                          </TableCell>

                        </TableRow>
                        : null
                    );
                  })}
                <TableRow>
                  <TableCell />
                  {/* <TableCell align="right">Saldo R$ </TableCell> */}
                  <TableCell align="right">
                    Saldo
                    <NumberFormat
                      displayType={'text'}
                      name="saldo"
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={' R$ '}
                      value={parseFloat(p.user.saldo)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </ThemeProvider>
    </div>
  );
}

export default EnhancedTable;