import React, { useEffect, useState, useContext } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import Pagination from '../../utils/Pagination';

import Cadastrar from '../Usuario/Cadastrar';
import Perfil from '../Usuario/Perfil';
import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Usuarios = (p) => {
  const isMedium = useMediaQuery('(max-width: 1072px)');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [selectedUser, setSelectedUser] = useState();
  const { loggedUser: user } = useContext(EscamboContext)
  const [usuarios, setUsuarios] = useState({
    columnsMobile: [
      { title: 'Nome', field: 'nome' },
      { 
        title: 'Saldo', 
        field: 'saldo', 
        type: 'numeric',
        render: rowData => `R$ ${parseFloat(rowData.saldo).toFixed(2)}`
      },
    ],
    columnsMedium: [
      { title: 'Nome', field: 'nome' },
      { 
        title: 'CPF/CNPJ', 
        field: 'cpfCNPJ',
        hidden: isMobile,
        render: rowData => (rowData.tipoPessoa === 'PJ')
        ? <NumberFormat value={rowData.cpfCNPJ} displayType={'text'} format="##.###.###/####-##" allowLeadingZeros={true}/>
        : <NumberFormat value={rowData.cpfCNPJ} displayType={'text'} format="###.###.###-##" allowLeadingZeros={true}/>
      },
      { 
        title: 'Saldo', 
        field: 'saldo', 
        type: 'numeric',
        render: rowData => `R$ ${parseFloat(rowData.saldo).toFixed(2)}`
      },
    ],
    columns: [
      { title: 'Nome', field: 'nome' },
      { title: 'E-mail', field: 'email', hidden: isMobile },
      { 
        title: 'CPF/CNPJ', 
        field: 'cpfCNPJ',
        hidden: isMobile,
        render: rowData => (rowData.tipoPessoa === 'PJ')
        ? <NumberFormat value={rowData.cpfCNPJ} displayType={'text'} format="##.###.###/####-##" allowLeadingZeros={true}/>
        : <NumberFormat value={rowData.cpfCNPJ} displayType={'text'} format="###.###.###-##" allowLeadingZeros={true}/>
      },
      { 
        title: 'Saldo', 
        field: 'saldo', 
        type: 'numeric',
        render: rowData => `R$ ${parseFloat(rowData.saldo).toFixed(2)}`
      },
    ],
    data: [],
  });
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);

  const handleClickAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  const handleClickEditUser = (user) => {
    setSelectedUser(user);
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  //buscar parceiros
  async function loadUsuarios() {

      const response = await api.get('Admin/Usuario/listar');
      setUsuarios((prevState) => {
        const data = response.data;
        return { ...prevState, data };
      });
  }

  useEffect(() => {

      if (user._id !== '') {
        loadUsuarios();
      }

  }, [user._id]);

  // useEffect(() => {
  //   setUsuarios((prevState) => {
  //     const columns = [...prevState.columns];
  //     columns[1].hidden = isMobile;
  //     columns[2].hidden = isMobile;
  //     return { ...prevState };
  //   });
  // }, [isMobile])

  return (
    <div>
            
        <div className="main-wide-container" >

            <div className="wide-container">

                <div className="row">
                    <div className="col">
                      <MaterialTable
                        title="Manutenção de Usuários"
                        components={{
                          Pagination: Pagination,
                        }}
                        columns={
                          (isMobile) 
                          ? usuarios.columnsMobile 
                          : (isMedium)
                            ? usuarios.columnsMedium
                            : usuarios.columns
                        }
                        data={usuarios.data}
                        actions={[
                          {
                            icon: 'add',
                            tooltip: 'Adicionar',
                            isFreeAction: true,
                            onClick: (event) => handleClickAddUser()
                          },
                          {
                            icon: 'edit',
                            tooltip: 'Editar',
                            isFreeAction: false,
                            onClick: (event, rowData) => handleClickEditUser(rowData)
                          }
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          pageSize: 10
                        }}
                        localization={{
                          pagination: {
                              labelDisplayedRows: '{from}-{to} de {count}',
                              labelRowsSelect: 'linhas'
                          },
                          toolbar: {
                              nRowsSelected: '{0} linha(s) selecionadas',
                              searchTooltip: 'Buscar',
                              searchPlaceholder: 'Buscar'
                          },
                          header: {
                              actions: 'Ações'
                          },
                          body: {
                              emptyDataSourceMessage: 'Nenhum registro para mostrar',
                              addTooltip: 'Criar',
                              editTooltip: 'Editar',
                              deleteTooltip: 'Deletar',
                              editRow: {
                                  cancelTooltip: 'Cancelar',
                                  saveTooltip: 'Salvar',
                                  deleteText: 'Tem certeza que quer apagar esse usuário?'
                              },
                              filterRow: {
                                  filterTooltip: 'Filtrar'
                              }
                          }
                        }}
                      />
                    </div>
                </div>

                <Dialog 
                  open={openAddUser} 
                  onClose={handleCloseAddUser} 
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  maxWidth={'lg'}
                >
                  <Cadastrar 
                    {...p} 
                    admin={true} 
                    loadUsuarios={loadUsuarios}
                    handleCloseAddUser={handleCloseAddUser}
                  />
                </Dialog>

                <Dialog 
                  open={openEditUser} 
                  onClose={handleCloseEditUser} 
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  maxWidth={'lg'}
                >
                  <Perfil 
                    {...p} 
                    admin={true} 
                    loadUsuarios={loadUsuarios}
                    handleCloseEditUser={handleCloseEditUser}
                    userToEdit={selectedUser}
                  />
                </Dialog>

            </div>

        </div>

    </div>
  );
}

export default Usuarios;