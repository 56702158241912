import React, { useEffect, useState, useContext } from 'react';
import MaterialTable from 'material-table';
import Pagination from '../../utils/Pagination';
import DismissIcon from '@material-ui/icons/MoneyOff'

import api from '../../services/api';

import EscamboContext from '../../EscamboContext';

const Permutas = (p) => {
  const { loggedUser: user } = useContext(EscamboContext)
  const [permutas, setPermutas] = useState({
    columns: [
      {
        title: 'Data',
        field: 'createdAt',
        render: rowData => new Intl.DateTimeFormat("pt-BR",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }).format(new Date(rowData.createdAt))
      },
      { title: 'Fornecedor', field: 'fornecedor.nome' },
      { title: 'Cliente', field: 'cliente.nome' },
      {
        title: 'Status',
        field: 'confirmada',
        render: rowData => {
          if (rowData.cancelada) return 'Cancelada'
          if (rowData.comissao > 0 && !rowData.comissaoPaga) return 'Aguardando Comissão'
          if (rowData.confirmada) return 'Confirmada'
          return 'Aguardando'
        }
      },
      {
        title: 'Valor',
        field: 'valor',
        type: 'numeric',
        render: rowData => `R$ ${parseFloat(rowData.valor).toFixed(2)}`
      },
      {
        title: 'Comissão',
        field: 'comissao',
        type: 'numeric',
        render: rowData => `R$ ${parseFloat(rowData.comissao).toFixed(2)}`
      },
    ],
    data: [],
  });

  //buscar permutas
  async function loadPermutas() {

    const response = await api.get('/Admin/Permutas');
    setPermutas((prevState) => {
      const data = response.data;
      return { ...prevState, data };
    });
  }

  useEffect(() => {

    if (user._id !== '') {
      loadPermutas();
    }

  }, [user._id]);

  const dispensarComissao = (permuta) => {
    api.post('/Permuta/dispensarComissao', permuta).then((response) => {
      if (response.status === 200) {
        p.app.notify('success', 'Comissão dispensada com sucesso!', 2);
        loadPermutas();
      } else {
        p.app.notify('danger', response.data, 2);
      }
    }).catch((err) => {
      console.error(err);
      if (err.response.data) { p.app.notify('danger', err.response.data, 2); };
    });
  }

  return (
    <div>

      <div className="main-wide-container" >

        <div className="wide-container">

          <div className="row">
            <div className="col">
              <MaterialTable
                title="Permutas"
                components={{
                  Pagination: Pagination,
                }}
                columns={permutas.columns}
                data={permutas.data}
                actions={[
                  (rowData) => rowData.comissao > 0 && !rowData.comissaoPaga && {
                    icon: DismissIcon,
                    tooltip: 'Dispensar Comissão',
                    isFreeAction: false,
                    onClick: (event, rowData) => dispensarComissao(rowData)
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'linhas'
                  },
                  toolbar: {
                    nRowsSelected: '{0} linha(s) selecionadas',
                    searchTooltip: 'Buscar',
                    searchPlaceholder: 'Buscar'
                  },
                  header: {
                    actions: ''
                  },
                  body: {
                    emptyDataSourceMessage: 'Nenhum registro para mostrar',
                    addTooltip: 'Criar',
                    editTooltip: 'Editar',
                    deleteTooltip: 'Deletar',
                    editRow: {
                      cancelTooltip: 'Cancelar',
                      saveTooltip: 'Salvar',
                      deleteText: 'Tem certeza que quer apagar esse usuário?'
                    },
                    filterRow: {
                      filterTooltip: 'Filtrar'
                    }
                  }
                }}
              />
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default Permutas;