import React from 'react';

import './Menu.css';

import { FaProductHunt, FaUserAlt, FaHandshake } from "react-icons/fa";
import { GiExitDoor, GiWallet } from "react-icons/gi";
import { IoIosPeople, IoIosSettings } from "react-icons/io";
import logo from '../assets/min-logo.svg';

import EscamboContext from '../EscamboContext';

class Menu extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            prodPath: "",
            permPath: "",
            partPath: "",
            extrPath: "",
            perfPath: "",
        }

        this.logout = this.logout.bind(this);
        this.goToAdmin = this.goToAdmin.bind(this);
        this.goToProduto = this.goToProduto.bind(this);
        this.goToParceiros = this.goToParceiros.bind(this);
        this.goToPermutas = this.goToPermutas.bind(this);
        this.goToExtrato = this.goToExtrato.bind(this);
        this.goToPerfil = this.goToPerfil.bind(this);
        this.updatePath = this.updatePath.bind(this);
    }

    componentDidMount(){

        const pathname = this.props.history.location.pathname;

        if (pathname.includes('/Usuario/Produto')) {
            this.setState({
                prodPath: "active"
            });
        } 

        if (pathname.includes('/Usuario/Parceiros')) {
            this.setState({
                partPath: "active"
            });
        } 

        if (pathname.includes('/Usuario/Permutas')) {
            this.setState({
                permPath: "active"
            });
        } 

        if (pathname.includes('/Usuario/Extrato')) {
            this.setState({
                extrPath: "active"
            });
        } 

        if (pathname.includes('/Usuario/Perfil')) {
            this.setState({
                perfPath: "active"
            });
        } 
    }

    logout() {

        this.props.app.removeCookie('loggedUser');
        this.props.history.push('/');
        window.location.reload();
    }

    goToAdmin() {

        this.props.history.push('/Admin');
    }

    updatePath(path) {

        this.setState({
            prodPath: "",
            permPath: "",
            partPath: "",
            extrPath: "",
            perfPath: "",
        });

        this.setState({
            [path]: "active"
        })
    }

    goToProduto() {
        this.props.history.push('/Usuario/Produto');
        this.updatePath('prodPath');
    }

    goToParceiros() {
        this.props.history.push('/Usuario/Parceiros');
        this.updatePath('partPath');
    }

    goToPermutas() {
        this.props.history.push('/Usuario/Permutas');
        this.updatePath('permPath');
    }

    goToExtrato() {
        this.props.history.push('/Usuario/Extrato');
        this.updatePath('extrPath');
    }
    
    goToPerfil() {
        this.props.history.push('/Usuario/Perfil');
        this.updatePath('perfPath');
    }

    render() {
        return (
            <EscamboContext.Consumer>
                {({loggedUser}) => (
                    <nav className="navbar fixed-top navbar-expand-lg navbar-light">
                        <a className="navbar-brand" href="/Usuario/Parceiros">
                            <div className="menu-icons mt-2"><img src={logo} alt='Escambo' className="logo"/></div>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#textoNavbar" aria-controls="textoNavbar" aria-expanded="false" aria-label="Alterna navegação">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="textoNavbar">
                            <ul className="navbar-nav mr-auto">
                            <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                                <div className={`menu-icons ${this.state.perfPath}`}>
                                    <button onClick={() => {this.goToPerfil()}}>
                                        <FaUserAlt className="menu-icon"/> Perfil
                                    </button>
                                </div>
                            </li>
                            <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                                <div className={`menu-icons ${this.state.prodPath}`}>
                                    <button onClick={() => {this.goToProduto()}}>
                                        <FaProductHunt className="menu-icon"/> Produtos/Serviços
                                    </button>
                                </div>
                            </li>
                            <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                                <div className={`menu-icons ${this.state.partPath}`}>
                                    <button onClick={() => {this.goToParceiros()}}>
                                        <IoIosPeople className="menu-icon"/> Parceiros
                                    </button>
                                </div>
                            </li>
                            <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                                <div className={`menu-icons ${this.state.permPath}`}>
                                    <button onClick={() => {this.goToPermutas()}}>
                                        <FaHandshake className="menu-icon"/> Permutas
                                    </button>
                                </div>
                            </li>
                            <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                                <div className={`menu-icons ${this.state.extrPath}`}>
                                    <button onClick={() => {this.goToExtrato()}}>
                                        <GiWallet className="menu-icon"/> Extrato
                                    </button>
                                </div>
                            </li>
                            </ul>
                            <span className="navbar-text">
                                <div className="menu-icons">
                                    {(loggedUser) && (loggedUser.papel === 'Admin')
                                    ? <button className="mr-5" onClick={() => {this.goToAdmin()}}>
                                        <IoIosSettings className="menu-icon"/> Admin
                                    </button>
                                    : null}
                                    <button onClick={() => {this.logout()}}>
                                        <GiExitDoor className="menu-icon"/> Sair
                                    </button>
                                </div>
                            </span>
                        </div>
                    </nav>     
                )}
            </EscamboContext.Consumer>
        );
    }
}


export default Menu;