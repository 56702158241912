import React, { useEffect, useState, useRef, useContext } from 'react';
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import TableProdutos from '../../layout/TableProdutos';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Produto = (p) => {

    const refProd = useRef(null);

    const {loggedUser} = useContext(EscamboContext);
    const [produtos, setProdutos] = useState([]);
    const [options] = useState([]);
    const [ofertas, setOfertas] = useState([]);
    const [desejos, setDesejos] = useState([]);
    const [produto, setProduto] = useState({ nome: '' });
    const produtoModel = ({ nome: '' });


    //buscar produtos
    async function loadProdutos() {

        const response = await api.get('/Produto/listar');
        setProdutos(response.data);

        const ofertas = await api.get('/Usuario/Oferta/listar');
        setOfertas(ofertas.data);

        const desejos = await api.get('/Usuario/Desejo/listar');
        setDesejos(desejos.data);
    }

    //cadastrar Oferta
    async function addOferta() {

        const response = await api.post('/Usuario/Oferta/cadastrar', {produto});

        if (response.status === 200) {
            p.app.notify('success', response.data, 2);
        } else {
            p.app.notify('danger', 'Erro ao adicionar oferta, favor tentar novamente.', 2);
        }

    }
    
    //remover Oferta
    async function delOferta(pOfertas, callback) {

        for (let index = 0; index < pOfertas.length; index++) {
            const oferta = pOfertas[index];

            const response = await api.post('/Usuario/Oferta/remover', {oferta});

            if (response.status === 200) {
                p.app.notify('warning', `Oferta de ${oferta.nome} removida!`, 2);
            } else {
                p.app.notify('danger', `Erro ao remover ${oferta.nome}, favor tentar novamente.`, 2);
            }

            loadProdutos();
        }

        callback();

    }

    //cadastrar Desejo
    async function addDesejo() {

        const response = await api.post('/Usuario/Desejo/cadastrar', {produto});

        if (response.status === 200) {
            p.app.notify('success', response.data, 2);
        } else {
            p.app.notify('danger', 'Erro ao adicionar desejo, favor tentar novamente.', 2);
        }

    }

    //remover Desejo
    async function delDesejo(pDesejos, callback) {

        for (let index = 0; index < pDesejos.length; index++) {
            const desejo = pDesejos[index];

            const response = await api.post('/Usuario/Desejo/remover', {desejo});

            if (response.status === 200) {
                p.app.notify('warning', `${desejo.nome} removido da lista de desejos!`, 2);
            } else {
                p.app.notify('danger', `Erro ao remover desejo ${desejo.nome}, favor tentar novamente.`, 2);
            }

            loadProdutos();
        }

        callback();

    }

    //cadastrar Produto
    async function handleSaveProduto(acao) {

        const response = await api.post('/Produto/cadastrar', {produto});

        if (response.status === 200) {
            if (acao === 'addOferta') {
                await addOferta();
            } else {
                await addDesejo();
            }
            setProduto(produtoModel);
            loadProdutos();
        } else {
            p.app.notify('danger', 'Erro ao cadastrar produto/serviço, favor tentar novamente.', 2);
        }

    }

    useEffect(() => {

        if (loggedUser._id !== '') loadProdutos();

    }, [loggedUser._id]);

    //Carregar produtos para popular lista de sugestões
    useEffect(() => {
        function populateOptions() {

            options.splice(0, options.length);

            for (let i = 0; i < produtos.length; i++) {
                let option = {
                    value: produtos[i].nome,
                    id: produtos[i]._id,
                    label: produtos[i].nome
                };

                options.push(option);
            }
        };

        if (produtos) {
            populateOptions();
        }

    }, [produtos, options]);

    async function handleChange(e) {

        if (e) {

            setProduto({
                ...produto,
                nome: e.value,
                _id: e.id
            });

        } else {

            produtoModel.nome = '';
            setProduto(produtoModel);
        }
    };

    const formatCreateLabel = inputValue => (
        <span style={{ color: '#3A1246' }}>Criar {inputValue}</span>
    );

    const SingleValue = props => (
        <components.SingleValue {...props}>
            {props.data.label}
        </components.SingleValue>
    );

    return (
        <div>
            
            <div className="main-container" >

                <div className="container">

                    <div className="row">
                        <div className="col" style={{textAlign: "center"}}>
                            <h2 className="main-title mb-1">Produtos e Serviços</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md mb-3">
                            <label htmlFor="nome">Nome</label>
                            <CreatableSelect
                                isClearable
                                ref={refProd}
                                name="nomeProduto"
                                id="idProduto"
                                options={options}
                                onChange={handleChange}
                                placeholder="Digite o nome do produto..."
                                formatCreateLabel={formatCreateLabel}
                                components={{ SingleValue }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md mb-3">
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg btn-block btn-save-inst"
                                onClick={() => handleSaveProduto('addOferta')}
                            >Adicionar em minhas Ofertas <LocalOfferIcon/></button>
                        </div>
                        <div className="col-md mb-3">
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg btn-block btn-save-inst"
                                onClick={() => handleSaveProduto('addDesejo')}
                            >Adicionar em meus Desejos <ShoppingCartIcon/></button>
                        </div>
                    </div>

                    {/* <!-- Tabelas de Ofertas e Desejos --> */}
                    <div className="row">
                        <div className="col">
                            <TableProdutos
                                rows={ofertas}
                                delRow={delOferta}
                                title={"Minhas Ofertas"}
                            />
                        </div>
                        <div className="col">
                            <TableProdutos
                                rows={desejos}
                                delRow={delDesejo}
                                title={"Meus Desejos"}
                            />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Produto;