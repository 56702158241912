import React, { useEffect, useState, useContext } from 'react';
import { components } from "react-select";
import Select from 'react-select';

import CartaoParceiro from '../../layout/CartaoParceiro';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Parceiros = (p) => {

    const {loggedUser: user, parceiros} = useContext(EscamboContext);
    const [produto, setProduto] = useState({ nome: '' });
    const [produtos, setProdutos] = useState([]);
    const [prodOptions] = useState([]);
    const produtoModel = ({ nome: '' });

    useEffect(() => {

        async function loadProdutos() {

            const response = await api.get('/Produto/listar');
            setProdutos(response.data);
        }

        loadProdutos();
    }, []);

    //Carregar produtos para popular lista de sugestões
    useEffect(() => {
        function feedProdutosOptions() {

            prodOptions.splice(0, prodOptions.length);

            for (let i = 0; i < produtos.length; i++) {
                let option = {
                    value: produtos[i].nome,
                    id: produtos[i]._id,
                    label: produtos[i].nome
                };

                prodOptions.push(option);
            }
        };

        if (produtos) {
            feedProdutosOptions();
        }

    }, [produtos, prodOptions]);

    async function handleProdChange(e) {

        if (e) {

            setProduto({
                ...produto,
                nome: e.value,
                _id: e.id
            });
        } else {

            produtoModel.nome = '';
            setProduto(produtoModel);
        }
    };

    const SingleProdValue = props => (
        <components.SingleValue {...props}>
            {props.data.label}
        </components.SingleValue>
    );

    return (
        <div>            
            <div className="main-wide-container" >

                <div className="wide-container">
                    
                    <div className="row">
                        <div className="col-md mb-3" style={{textAlign: "center"}}>
                            <h2 className="main-title mb-1">Lista de Parceiros</h2>
                        </div>
                    </div>

                    <div className="wide-container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 offset-lg-3">
                                <Select
                                    isClearable
                                    name="nomeProduto"
                                    id="idProduto"
                                    options={prodOptions}
                                    onChange={handleProdChange}
                                    placeholder="Procure por produto/serviço..."
                                    components={{ SingleProdValue }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="wide-container">

                      { parceiros && parceiros.length > 0 ? (
                          <div className="row">
                              {parceiros.map(parceiro => (
                                 (parceiro._id !== user._id) &&
                                 ((parceiro.oferece.findIndex(prod => prod.nome === produto.nome) > -1
                                 || parceiro.deseja.findIndex(prod => prod.nome === produto.nome) > -1
                                 || produto.nome === '')) ?
                                  <div className="col-12 col-lg-6 col-xl-4" key={parceiro._id}>
                                    <CartaoParceiro
                                      key={parceiro._id}
                                      parceiro={parceiro}
                                      nomeBuscado={produto.nome}
                                    />
                                  </div>
                                   : null
                              ))}                
                          </div>
                      ) : (
                          <div className="empty">Carregando dados...</div>
                      ) }

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Parceiros;