import React, { useState, useEffect, useContext } from 'react';
import Chip from '@material-ui/core/Chip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NumberFormat from 'react-number-format';
import { QRCode } from 'react-qrcode-logo';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';
import logo from '../../assets/min-logo.svg';

const PaymentLink = (props) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const link = `https://escambo.app/Pagar/${props.cpfCNPJ}`

    const copyLink = () => {
        navigator.clipboard.writeText(link)
        setLinkCopied(true)
    }

    useEffect(() => {

        if (linkCopied) setTimeout(() => {
            setLinkCopied(false)
        }, 3000);

    }, [linkCopied])

    if (!props.cpfCNPJ) return null
    return (
        <div className="d-flex flex-wrap justify-content-between">
            <div className="mb-3">
                <h6 className="main-title mb-1">Seu link para receber pagamentos</h6>
                <Chip 
                    color="primary" 
                    label={link}
                    deleteIcon={(linkCopied) ? <CheckCircleIcon /> : <FileCopyIcon />} 
                    onDelete={copyLink}
                    onClick={copyLink}
                    clickable
                /> 
            </div>
            <div onClick={() => props.history.push(`/PrintQR/${props.cpfCNPJ}`)}>
                <QRCode 
                    className="float-right" 
                    value={link} 
                    logoImage={logo} 
                    logoWidth={60} 
                    size={150} 
                />
            </div>
        </div>
    )
}

const Cadastrar = (p) => {

    const { loggedUser, estados } = useContext(EscamboContext);
    const [user, setUser] = useState(loggedUser);

    //atualiza dados do usuário 
    const modifyUser = e => {

        //substituir , por . em campos numericos, para computar os decimais
        let value = (e.target.type === 'number') ? e.target.value.replace(',', '.') : e.target.value;

        //pegar apenas os números desses campos
        if (['cpfCNPJ', 'cep', 'telefone', 'whatsapp'].indexOf(e.target.name) >= 0) { 
            value = value.replace(/\D/g, "");
        }

        //converter  valores booleanos
        if (['true', 'false'].indexOf(e.target.value) >= 0) { 
            (e.target.value === 'true') 
                ? value = true
                : value = false
        }

        setUser({
            ...user,
            [e.target.name]: value,
        });
    }

    const handleDelete = () => {
        if (!p.admin) return;

        if (!window.confirm("Tem certeza que quer deletar esse usuário?")) return;

        api.post('/Admin/Usuario/remover', { id: user._id ?? user.id }).then((response) => {
            if (response.status === 200) {
                p.app.notify('success', 'Usuário removido com sucesso!', 2);
                p.loadUsuarios();
                p.handleCloseEditUser();
            } else {
                p.app.notify('warning', 'Erro ao remover usuário, favor tentar novamente.', 2);
            }
        }).catch((err) => {
            console.error(err);
            p.app.notify('danger', err.response.data, 2);
        });
    }

    async function handleUserRegistration(e) {
        e.preventDefault();

        let path = '';
        (p.admin) ? path = '/Admin/Usuario/alterar' : path = '/Usuario/alterar';

        api.post(path, { user }).then((response) => {
            if (response.status === 200) {
                p.app.notify('success', 'Usuário alterado com sucesso!', 2);
                if (p.admin) {
                    p.loadUsuarios() 
                    p.handleCloseEditUser()
                } else {
                    setUser(response.data)
                    p.app.setCookie('loggedUser', response.data)
                }
            } else {
                p.app.notify('warning', 'Erro ao alterar usuário, favor tentar novamente.', 2);
            }
        }).catch((err) => {
            console.error(err);
            p.app.notify('danger', err.response.data, 2);
        });
    }

    async function reenviarEmail(e) {

        e.preventDefault();

        api.post('/email/reconfirmar', {
            user
        }).then((response) => {
            if (response.status === 200) {
                p.app.notify('success', 'E-mail de confirmação reenviado.', 2);
            } else {
                p.app.notify('warning', response.data, 2);
            }
        }).catch((err) => {
            console.error(err);
            p.app.notify('danger', err.response.data, 2);
        });
    }

    //carregar usuario
    useEffect(() => {

        if (p.admin) {
            setUser(p.userToEdit);
        }
        else if (!loggedUser) {
            p.app.alterState('whereTo',p.history.location.pathname);
            p.history.push('/');
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            
            <div className="main-container">

                <div className="container">

                    <div className="row">

                        {/* Dados do Usuário */}
                        <div className="col-md">

                            <div className="row">
                                <div className="col-md-8 mb-3">
                                    <h4 className="main-title mb-1">Perfil do Usuário</h4>
                                </div>
                            </div>

                            <PaymentLink history={p.history} cpfCNPJ={user.cpfCNPJ} />

                            {(user) ?
                            <form className="needs-validation" noValidate="" onSubmit={handleUserRegistration}>

                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="email">E-mail</label>
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            onChange={modifyUser}
                                            value={user.email}
                                        />
                                    </div>
                                    {(!user.confirmed) ?
                                    <div className="col-12 mb-3">
                                        <span style={{
                                            width: '100%',
                                            marginTop: '.25rem',
                                            fontSize: '80%',
                                            color: '#dc3545'
                                        }}>
                                            E-mail ainda não confirmado, favor checar suas caixas de entrada, SPAM e Lixo Eletrônico. 
                                        </span>
                                        <span style={{width: '100%', marginTop: '0.25rem', fontSize: '80%'}}>
                                            <a href="/" onClick={(e) => reenviarEmail(e)}> Reenviar e-mail de confirmação.</a>
                                        </span>
                                    </div>
                                : null}
                                </div>

                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-4 mb-3">
                                        <label htmlFor="tipoPessoa"> Tipo Pessoa </label><br/>
                                        <input 
                                            name="tipoPessoa" 
                                            type="radio"
                                            value="PF"
                                            onChange={modifyUser}
                                            checked={user.tipoPessoa==="PF"}
                                        /> <label htmlFor="PF"> Física </label>
                                        <input 
                                            name="tipoPessoa" 
                                            className="ml-4"
                                            type="radio"
                                            value="PJ"
                                            onChange={modifyUser}
                                            checked={user.tipoPessoa==="PJ"}
                                        /> <label htmlFor="PJ"> Jurídica </label>
                                    </div>

                                    {(p.admin) ?
                                    <div className="col-sm-12 col-md-4 mb-3">
                                        <label htmlFor="confirmed"> E-mail confirmado? </label><br/>
                                        <input 
                                            name="confirmed" 
                                            type="radio"
                                            value={true}
                                            onChange={modifyUser}
                                            checked={user.confirmed}
                                        /> <label htmlFor="confirmed"> Sim </label>
                                        <input 
                                            name="confirmed" 
                                            className="ml-4"
                                            type="radio"
                                            value={false}
                                            onChange={modifyUser}
                                            checked={!user.confirmed}
                                        /> <label htmlFor="confirmed"> Não </label>
                                    </div>
                                    : null }
                                    
                                    {(p.admin) ?
                                    <div className="col-sm-12 col-md-4 mb-3">
                                        <label htmlFor="ativo"> Usuário ativo? </label><br/>
                                        <input 
                                            name="ativo" 
                                            type="radio"
                                            value={true}
                                            onChange={modifyUser}
                                            checked={user.ativo}
                                        /> <label htmlFor="ativo"> Sim </label>
                                        <input 
                                            name="ativo" 
                                            className="ml-4"
                                            type="radio"
                                            value={false}
                                            onChange={modifyUser}
                                            checked={!user.ativo}
                                        /> <label htmlFor="ativo"> Não </label>
                                    </div>
                                    : null }
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nome"
                                            value={user.nome}
                                            onChange={modifyUser}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                {(user.tipoPessoa === "PF"
                                    ?
                                        <div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="cpfCNPJ">CPF</label>
                                                    <NumberFormat 
                                                        format="###.###.###-##" 
                                                        className="form-control"
                                                        name="cpfCNPJ"
                                                        value={user.cpfCNPJ}
                                                        onChange={modifyUser}
                                                        placeholder={"Digitar apenas números"}
                                                        required={(user.tipoPessoa === "PF") ? true : false}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="inscEstRG">RG</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="inscEstRG"
                                                        value={user.inscEstRG}
                                                        onChange={modifyUser}
                                                        placeholder={"Informe seu documento de identidade"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="nome">Razão Social</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="razaoSocial"
                                                        value={user.razaoSocial}
                                                        onChange={modifyUser}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="cpfCNPJ">CNPJ</label>
                                                    <NumberFormat 
                                                        format="##.###.###/####-##" 
                                                        className="form-control"
                                                        name="cpfCNPJ"
                                                        value={user.cpfCNPJ}
                                                        onChange={modifyUser}
                                                        placeholder={"Digitar apenas números"}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="inscEstRG">Inscrição Estadual</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="inscEstRG"
                                                        value={user.inscEstRG}
                                                        onChange={modifyUser}
                                                        placeholder={"Deixar em branco caso seja isento"}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="contato">Contato</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="contato"
                                                        value={user.contato}
                                                        onChange={modifyUser}
                                                        placeholder={"Informe o nome da pessoa para contato direto"}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                )}

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Endereço</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="endereco"
                                            value={user.endereco}
                                            onChange={modifyUser}
                                            placeholder={"Informe seu endereço completo: Rua, Nº e Complemento"}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Bairro</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="bairro"
                                            value={user.bairro}
                                            onChange={modifyUser}
                                            required
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Cidade</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="cidade"
                                            value={user.cidade}
                                            onChange={modifyUser}
                                            required
                                        />
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="estados">Estado</label>
                                        <select
                                            className="custom-select d-block w-100"
                                            name="estado"
                                            value={user.estado}
                                            onChange={modifyUser}
                                            required
                                        >
                                            <option>Selecionar estado...</option>
                                            {(estados && estados.length > 0) ?  
                                                estados.map(uf => (
                                                    <option key={uf._id} value={uf._id}> 
                                                        {uf.nome} 
                                                    </option>
                                                ))
                                            : null}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">CEP</label>
                                        <NumberFormat 
                                            format="#####-###" 
                                            className="form-control"
                                            name="cep"
                                            value={user.cep}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="telefone">Telefone</label>
                                        <NumberFormat 
                                            format="(##) #####-####" 
                                            className="form-control"
                                            name="telefone"
                                            value={user.telefone}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="whatsapp">Whatsapp</label>
                                        <NumberFormat 
                                            format="(##) #####-####" 
                                            className="form-control"
                                            name="whatsapp"
                                            value={user.whatsapp}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                </div>
                                
                                {(p.admin) ?
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="saldo">Saldo</label>
                                        <input 
                                            type="number"
                                            className="form-control"
                                            name="saldo"
                                            value={user.saldo}
                                            disabled
                                            onChange={modifyUser}
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="percentualComissao">Percentual Comissão</label>
                                        <input 
                                            type="number"
                                            className="form-control"
                                            name="percentualComissao"
                                            value={user.percentualComissao}
                                            onChange={modifyUser}
                                        />
                                    </div>
                                </div>
                                : null}

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="btn-save-user"></label>
                                        <button
                                            type="submit"
                                            id="btn-save-user"
                                            className="btn btn-primary btn-lg btn-block"
                                        >Salvar</button>
                                    </div>
                                </div>        

                                {p.admin && (<div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="btn-save-user"></label>
                                        <button
                                            type="button"
                                            onClick={handleDelete}
                                            id="btn-save-user"
                                            className="btn btn-primary btn-lg btn-block"
                                            style={{ backgroundColor: '#dc3545' }}
                                        >Deletar</button>
                                    </div>
                                </div>)}                        

                            </form>
                            : null}

                        </div>

                    </div>

                </div>

            </div>


        </div>
    );
}

export default Cadastrar;