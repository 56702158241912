import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Button from '@material-ui/core/Button';

import './Comprovante.css';

import logo from '../../assets/logo.svg';

import api from '../../services/api';

const listStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    marginTop: 5,
  },
  ul: {
    padding: 0,
  },
  li: {
    padding: 0,
  },
}));

const Comprovante = (p) => {

    const listClasses = listStyles();
    const [cliente, setCliente] = useState({
        _id: ''
    });
    const [fornec, setFornec] = useState({
        _id: ''
    });

    async function getPartesPermuta() {

        const idFornecedor = p.location.state.permuta.fornecedor._id || p.location.state.permuta.fornecedor;
        const idCliente = p.location.state.permuta.cliente._id || p.location.state.permuta.cliente;

        api.get(`/Usuario/find/${idFornecedor}`).then((response, err) => {

            if (response.status === 200) {
                   
                setFornec(response.data);
            } else {
                const error = new Error(response.error);
                throw error;
            }

        }).catch((err) => {
            console.error(err);
        });

        api.get(`/Usuario/find/${idCliente}`).then((response, err) => {

            if (response.status === 200) {
                  
                setCliente(response.data);
            } else {
                const error = new Error(response.error);
                throw error;
            }

        }).catch((err) => {
            console.error(err);
        });
    }

    //carregar partes da permuta
    useEffect(() => {

        if (p.location.state.permuta) {
          getPartesPermuta();
        }
    // eslint-disable-next-line
    }, [p.location.state.permuta]);

    return (
      <div className="book">

          <div className="col-6 offset-3 text-center">
            <Button 
              className="button mt-2"
              variant="contained" 
              onClick={() => window.print()}
            >
              IMPRIMIR
            </Button>
            <Button 
              className="button mt-2 ml-2"
              variant="contained" 
              onClick={() => p.history.goBack()}
            >
              VOLTAR
            </Button>
          </div>

          <div className="page">
              <div className="subpage">

                <div className="row">

                    <div className="col-6">
                      <img src={logo} alt='Escambo' style={{width: '7cm'}} />
                    </div>
                    <div className="col-6">
                      <p className="text-right font-weight-bold text-header">AUTORIZAÇÃO DE PERMUTA.</p>
                      <p className="text-right">PEDIDO Nº: {p.location.state.permuta.id}</p>
                    </div>

                </div>

                <div className="row mt-3">

                    <div className="col-12 grid-partes-1st">
                      <p className="grid-header">Fornecedor</p>
                      <p className="font-weight-bold grid-content">{fornec.nome}</p>
                    </div>
                    <div className="col-12 grid-partes">
                      <p className="grid-header">Cliente</p>
                      <p className="font-weight-bold grid-content">{cliente.nome}</p>
                    </div>

                    <div className="col-4 grid-partes">
                      <p className="grid-header">CPF/CNPJ</p>
                      <p className="grid-content">
                          <NumberFormat 
                              format={(cliente.tipoPessoa === 'PF') ? "###.###.###-##" : "##.###.###/####-##" }
                              name="cpfCNPJ"
                              value={cliente.cpfCNPJ}
                              displayType="text"
                          />
                      </p>
                    </div>
                    <div className="col-4 grid-partes">
                      <p className="grid-header">Insc. Estadual / RG</p>
                      <p className="grid-content">{cliente.inscEstRG}</p>
                    </div>
                    <div className="col-4 grid-partes">
                      <p className="grid-header">Contato</p>
                      <p className="grid-content">{cliente.contato}</p>
                    </div>

                    <div className="col-12 grid-partes">
                      <p className="grid-header">Endereço</p>
                      <p className="grid-content">{cliente.endereco}</p>
                    </div>

                    <div className="col-3 grid-partes">
                      <p className="grid-header">Bairro</p>
                      <p className="grid-content">{cliente.bairro}</p>
                    </div>
                    <div className="col-3 grid-partes">
                      <p className="grid-header">Cidade</p>
                      <p className="grid-content">{cliente.cidade}</p>
                    </div>
                    <div className="col-1 grid-partes">
                      <p className="grid-header">UF</p>
                      <p className="grid-content">{(cliente.estado) ? cliente.estado.sigla : ''}</p>
                    </div>
                    <div className="col-2 grid-partes">
                      <p className="grid-header">CEP</p>
                      <p className="grid-content">{cliente.cep}</p>
                    </div>
                    <div className="col-3 grid-partes">
                      <p className="grid-header">Telefone</p>
                      <p className="grid-content">{cliente.telefone}</p>
                    </div>

                </div>

                <div className="row mt-2">

                    <div className="col-12" style={{backgroundColor: '#e2e2e2'}}>
                      <p className="font-weight-bold grid-content text-center">DESCRIMINAÇÃO DOS SERVIÇOS/PRODUTOS</p>
                    </div>

                    <div className="col-12 mt-2">
                      <List dense className={listClasses.root}>
                        <li>
                          <ul className={listClasses.ul}>
                            {p.location.state.permuta.produtos.map((produto, index) => (
                              <ListItem key={index} className={listClasses.li}>
                                <FiberManualRecordIcon 
                                  style={{"height":"5px"}}
                                />
                                <ListItemText 
                                  primary={`${produto.nome} - R$ ${parseFloat(produto.valor).toFixed(2)}`} 
                                />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      </List>
                    </div>

                    <div className="col-4 offset-8 grid-partes-1st" style={{padding: "2px"}}>
                      <h4 style={{padding: "0", margin: "0"}}>Total:  
                        <NumberFormat 
                            className="ml-2"
                            displayType={'text'} 
                            name="valor"
                            thousandSeparator={'.'} 
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={'R$ '} 
                            value={p.location.state.permuta.valor || 0} 
                        />
                      </h4>
                    </div>

                </div>

                <div className="row mt-2">

                    <div className="col-12" style={{backgroundColor: '#e2e2e2'}}>
                      <p className="font-weight-bold grid-content text-center">OBSERVAÇÔES</p>
                    </div>

                    <div className="col-12 mt-2">
                      <p>{p.location.state.permuta.observacoes}</p>
                    </div>

                </div>

                <div className="row mt-2">

                    <div className="col-12" style={{backgroundColor: '#e2e2e2'}}>
                      <p className="font-weight-bold text-center" style={{fontSize: "8pt", marginBottom: "0"}}>
                        AUTORIZAMOS E ASSUMIMOS AS RESPONSABILIDADES DESCRITAS ACIMA NAS CONDIÇÕES ESPECIFICADAS
                      </p>
                    </div>

                    <div className="col-12 mt-2">
                      <p>
                        Maceió, &nbsp; 
                        {new Intl.DateTimeFormat("pt-BR", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit"
                        }).format(new Date(p.location.state.permuta.createdAt))}
                      </p>
                    </div>

                    <div className="col-4 mt-5">
                      <p className="grid-header text-center" style={{borderTop: "2px black solid"}}>ASSINATURA ESCAMBO</p>    
                    </div>

                    <div className="col-4 mt-5" style={{borderTop: "2px black solid"}}>
                      <p className="grid-header text-center">ASSINATURA DO FORNECEDOR</p>    
                    </div>

                    <div className="col-4 mt-5">
                      <p className="grid-header text-center" style={{borderTop: "2px black solid"}}>ASSINATURA DO CLIENTE</p>    
                    </div>

                </div>

                <div className="row mt-5">
                    <p className="col-12 text-center" style={{fontSize: "8pt", marginBottom: "2px"}}>
                    "Entrega o teu caminho no Senhor, confia nele; e o mais ele fará". Sl 37-5
                    </p>
                    <p className="col-12 text-center" style={{fontSize: "8pt", marginBottom: "2px"}}>
                    escarnbo.al@hotmal.com | @escambobr | https://escambo.app/
                    </p>
                    <p className="col-12 text-center" style={{fontSize: "8pt", marginBottom: "2px"}}>
                    Fones: 82-99940-1902
                    </p>
                </div>
              
              </div>    
          </div>
      </div>
    );
}

export default Comprovante;