import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/Login';
import ForgotPass from './pages/ForgotPass';
import ChangePass from './pages/ChangePass';
import Pagar from './pages/Pagar';

import Cadastrar from './pages/Usuario/Cadastrar';
import Produto from './pages/Usuario/Produto';
import Parceiros from './pages/Usuario/Parceiros';
import Permutas from './pages/Usuario/Permutas';
import Perfil from './pages/Usuario/Perfil';
import Extrato from './pages/Usuario/Extrato';
import Comprovante from './pages/Usuario/Comprovante';
import PrintQR from './pages/Usuario/PrintQR';
import PermutaDialog from './pages/Usuario/PermutaDialog';

import Usuarios from './pages/Admin/Usuarios';
import PermutasAdmin from './pages/Admin/Permutas';
import Extratos from './pages/Admin/Extratos';

import Menu from './layout/Menu';
import AdminMenu from './layout/AdminMenu';

import EscamboContext from './EscamboContext';

const NavRoute = ({p, user, exact, path, component: Component}) => (
    (user) ?
        <Route exact={exact} path={path} render={(props) => (
            <div>
            <Menu {...props} app={p}/>
            <Component {...props} app={p}/>
            <PermutaDialog {...props} app={p}/>
            <footer className="footer text-muted text-center text-small">
                <p className="mb-1">© 2020 Oxedev</p>
            </footer>
            </div>
        )}/>
    :   <Redirect
            to={{
                pathname: '/'
            }}
        />
)

const AdmRoute = ({p, user, exact, path, component: Component}) => (
    (user.papel === 'Admin') ?
        <Route exact={exact} path={path} render={(props) => (
            <div>
            <AdminMenu {...props} app={p}/>
            <Component {...props} app={p}/>
            <footer className="footer text-muted text-center text-small">
                <p className="mb-1">© 2020 Oxedev</p>
            </footer>
            </div>
        )}/>
    :   <Redirect
            to={{
                pathname: '/'
            }}
        />
)

class Routes extends React.Component {

    render() {    
        return (
            <EscamboContext.Consumer>
                {(app) => (
                    <BrowserRouter app={this.props}>
                        <Switch>
                            <Route path="/" exact render={(props) => <Login {...props} app={this.props} />} />
                            <Route path="/Usuario/Cadastrar" render={(props) => <Cadastrar {...props} app={this.props}/>} />
                            <Route path="/Usuario/EsqueciMinhaSenha" render={(props) => <ForgotPass {...props} app={this.props}/>} />
                            <Route path="/Confirm/:id" render={(props) => <Login {...props} app={this.props}/>} />
                            <Route path="/AlterarSenha/:token" render={(props) => <ChangePass {...props} app={this.props}/>} />
                            <Route path="/Pagar/:fornec" render={(props) => <Pagar {...props} app={this.props}/>} />
                            <Route path="/Comprovante/:idPermuta" render={(props) => <Comprovante {...props} app={this.props}/>} />
                            <Route path="/PrintQR/:link" render={(props) => <PrintQR {...props} />} />
                            <NavRoute p={this.props} user={app.loggedUser} exact path="/Usuario/Perfil" component={Perfil}/>
                            <NavRoute p={this.props} user={app.loggedUser} exact path="/Usuario/Produto" component={Produto}/>
                            <NavRoute p={this.props} user={app.loggedUser} exact path="/Usuario/Parceiros" component={Parceiros}/>
                            <NavRoute p={this.props} user={app.loggedUser} exact path="/Usuario/Permutas" component={Permutas}/>
                            <NavRoute p={this.props} user={app.loggedUser} exact path="/Usuario/Extrato" component={Extrato}/>
                            <AdmRoute p={this.props} user={app.loggedUser} exact path="/Admin" component={Usuarios}/>
                            <AdmRoute p={this.props} user={app.loggedUser} exact path="/Admin/Usuarios" component={Usuarios}/>
                            <AdmRoute p={this.props} user={app.loggedUser} exact path="/Admin/Permutas" component={PermutasAdmin}/>
                            <AdmRoute p={this.props} user={app.loggedUser} exact path="/Admin/Extratos" component={Extratos}/>
                        </Switch>
                    </BrowserRouter>
                )}
            </EscamboContext.Consumer>
        );
    }
}

export default Routes;
