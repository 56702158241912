import React, { useEffect, useState, useContext } from 'react';

import './Login.css';

import api from '../services/api';
import EscamboContext from '../EscamboContext';

import logo from '../assets/logo.svg';

const Login = (props) => {
    const { loggedUser, whereTo } = useContext(EscamboContext);
    const [user, setUser] = useState({
        email: '',
        password: '',
        confirmed: false
    });
    const [msgErro, setMsgErro] = useState({ error: '' });

    useEffect(() => {

        if (loggedUser) {
            props.history.push(whereTo || '/Usuario/Produto');
        }
    }, [loggedUser, whereTo, props.history]);

    useEffect(() => {

        const notify = props.app.notify;
        const setCookie = props.app.setCookie;

        function confirmEmail(id) {
            api.get(`/email/confirm/${id}`).then((response) => {
    
                if (response.status === 200) {
                    notify('success', response.data.message, 2);
                    setUser({
                        ...this.loggedUser,
                        confirmed: true
                    });
                    setCookie('loggedUser', this.user);
                    
                } else {
                    const error = new Error(response.error);
                    throw error;
                }
    
            }).catch((err) => {
                notify('warning', err.response.data.error, 2);
            });
        }

        if (props.match.path === '/Confirm/:id') {
            confirmEmail(props.match.params.id);
        }
    }, [props, props.app.notify, props.match.params.id, props.match.path]);

    const updateUser = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault();

        api.post('/Usuario/login', {
            email: user.email,
            password: user.password
        }).then((response) => {
            if (response.status === 200) {
                const { _id } = response.data;

                if (_id !== null) {
                    props.app.setCookie('loggedUser', response.data);
                }
            } else {
                const error = new Error(response.error);
                throw error;
            }
        }).catch((err) => {
            console.error(err);
            if (err.response && err.response.data) { setMsgErro(err.response.data) };
        });
    }

    function registerUser() {
        props.history.push('/Usuario/Cadastrar');
    }

    return (
        <div className="login-container">

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <img src={logo} alt='Escambo' />
                    </div>
            
                    <div className="col-lg-6 offset-lg-3">
                    <form onSubmit={handleSubmit}>
                        {/* <img src={logo} alt='Cakey' /> */}
                        <input
                            placeholder="Digite seu e-mail"
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={updateUser}
                        />
                        <input
                            placeholder="Digite sua senha"
                            type="password"
                            name="password"
                            value={user.password}
                            onChange={updateUser}
                        />
                        <span style={{
                            width: '100%',
                            marginTop: '.25rem',
                            fontSize: '80%',
                        }}>
                            <a href={'/Usuario/EsqueciMinhaSenha'}>Esqueceu sua senha?</a>
                        </span>

                        <span style={{
                            width: '100%',
                            marginTop: '.25rem',
                            fontSize: '80%',
                            color: '#dc3545'
                        }}>
                            {msgErro.error}
                        </span>

                        <button type="submit">Entrar</button>
                        <button type="button" className="register-button" onClick={registerUser}>
                            Cadastrar-se
                        </button>
                    </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Login;