import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import TableExtrato from '../../layout/TableExtrato';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Extratos = (p) => {

  const classes = useStyles();
  const { loggedUser: user, parceiros } = useContext(EscamboContext);
  const [permutas, setPermutas] = useState([]);
  const [parceiro, setParceiro] = useState({});

  const handleChange = (event) => {
    setParceiro(event.target.value);
  };

  //buscar produtos
  async function loadPermutas() {

    const response = await api.post('/Permuta/listar', { parceiro: { _id: parceiro._id || user._id } });
    setPermutas(response.data);
  }

  //carregar usuario
  useEffect(() => {

    if (!user) {
      p.app.alterState('whereTo', p.history.location.pathname);
      p.history.push('/');
    };
  });

  //carregar permutas
  useEffect(() => {

    if (parceiro || user) {
      loadPermutas();
    }
    // eslint-disable-next-line
  }, [user._id, parceiro]);

  useEffect(() => {
    const loadParceiros = () => {
      api.get('/Usuario/listar').then((response) => {
        p.app.alterState('parceiros', response.data);
      }).catch((err) => {
        console.error(err);
      });
    }

    if (!parceiros) {
      loadParceiros()
    }
  }, [parceiros])

  return (
    <div>

      <div className="main-container" >

        <div className="container">

          <div className="row">
            <div className="col-md mb-3" style={{ textAlign: "center" }}>
              <h2 className="main-title mb-1">Extrato Movimentação</h2>
            </div>
          </div>

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">Parceiro</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={parceiro}
              onChange={handleChange}
              style={{ maxHeight: "200px" }}
            >
              {(parceiros && parceiros.length > 0)
                ? parceiros
                  .sort(function (a, b) { return a.nome > b.nome ? 1 : -1 })
                  .map((parceiro) => (<MenuItem value={parceiro}>{parceiro.nome}</MenuItem>))
                : null
              }
            </Select>
            <FormHelperText>Selecione o parceiro que deseja visualizar o extrato</FormHelperText>
          </FormControl>

          <div className="row">
            <div className="col-1 mb-3" style={{ textAlign: "center" }}>
              <h6 className="main-title mb-1">Saldo</h6>
              <Chip
                color="primary"
                avatar={<Avatar>R$</Avatar>}
                label={(parceiro.saldo)
                  ? (Math.round((parceiro.saldo) * 100) / 100).toFixed(2)
                  : (user.saldo && !parceiro)
                    ? (Math.round((user.saldo) * 100) / 100).toFixed(2)
                    : "0.00"
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <TableExtrato
                rows={permutas}
                user={parceiro}
                history={p.history}
              />
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default Extratos;