import React, { useState, useContext } from 'react';
import NumberFormat from 'react-number-format';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Cadastrar = (p) => {

    const { estados } = useContext(EscamboContext);
    const [user, setUser] = useState({
        tipoPessoa: "PJ",
        papel: "Usuário"
    });
    const [changePassword, setChangePassword] = useState({
        password: '',
        pass_confirmation: '',
        error: ''
    });

    //atualiza dados do usuário 
    const modifyUser = e => {

        //substituir , por . em campos numericos, para computar os decimais
        let value = (e.target.type === 'number') ? e.target.value.replace(',', '.') : e.target.value;

        //pegar apenas os números desses campos
        if (['cpfCNPJ', 'cep', 'telefone', 'whatsapp'].indexOf(e.target.name) >= 0) { 
            value = value.replace(/\D/g, "");
        }

        setUser({
            ...user,
            [e.target.name]: value,
        });
    }

    //atualizar senha
    const modifyPassword = e => {

        setChangePassword({
            ...changePassword,
            [e.target.name]: e.target.value,
        });
    }

    async function handleUserRegistration(e) {
        e.preventDefault();

        if (user.tipoPessoa === "PF") {
            setUser({
                ...user,
                razaoSocial: user.nome,
                contato: user.nome,
            });
        }

        (p.admin) ? user.confirmed = true : user.confirmed = false

        user.password = changePassword.pass_confirmation;

        api.post('/Usuario/cadastrar', {
            user
        }).then((response) => {

            if (response.status === 200) {

                p.app.notify(
                    'success', 
                    (p.admin)
                        ? 'Usuário cadastrado com sucesso, verifique seu e-mail para confirmação.'
                        : 'Usuário cadastrado com sucesso.', 
                    2
                );

                if (p.admin) {
                    p.loadUsuarios() 
                    p.handleCloseAddUser()
                } else {
                    p.history.push('/');
                }

            } else {
                p.app.notify('warning', response.data, 2);
            }
        }).catch((err) => {
            console.error(err);
            p.app.notify('danger', err.response.data, 2);
        });
    }

    return (
        <div>

            <div className="main-container">

                <div className="container">

                    <div className="row">

                        {/* Dados do Usuário */}
                        <div className="col-md">

                            <div className="row">
                                <div className="col-md-8 mb-3">
                                    <h4 className="main-title mb-1">Cadastro do Usuário</h4>
                                </div>
                            </div>

                            <form className="needs-validation" noValidate="" onSubmit={handleUserRegistration}>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">E-mail</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            value={user.email || ''}
                                            onChange={modifyUser}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="tipoPessoa"> Tipo Pessoa </label><br/>
                                        <input 
                                            name="tipoPessoa" 
                                            type="radio"
                                            value="PF"
                                            onChange={modifyUser}
                                            checked={user.tipoPessoa==="PF"}
                                        /> <label htmlFor="PF"> Pessoa Física </label>
                                        <input 
                                            name="tipoPessoa" 
                                            className="ml-4"
                                            type="radio"
                                            value="PJ"
                                            onChange={modifyUser}
                                            checked={user.tipoPessoa==="PJ"}
                                        /> <label htmlFor="PJ"> Pessoa Jurídica </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nome"
                                            value={user.nome}
                                            onChange={modifyUser}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                {(user.tipoPessoa === "PF"
                                    ?
                                        <div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="cpfCNPJ">CPF</label>
                                                    <NumberFormat 
                                                        format="###.###.###-##" 
                                                        className="form-control"
                                                        name="cpfCNPJ"
                                                        value={user.cpfCNPJ}
                                                        onChange={modifyUser}
                                                        placeholder={"Digitar apenas números"}
                                                        required={(user.tipoPessoa === "PF") ? true : false}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="inscEstRG">RG</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="inscEstRG"
                                                        value={user.inscEstRG}
                                                        onChange={modifyUser}
                                                        placeholder={"Informe seu documento de identidade"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="nome">Razão Social</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="razaoSocial"
                                                        value={user.razaoSocial}
                                                        onChange={modifyUser}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md mb-3">
                                                    <label htmlFor="cpfCNPJ">CNPJ</label>
                                                    <NumberFormat 
                                                        format="##.###.###/####-##" 
                                                        className="form-control"
                                                        name="cpfCNPJ"
                                                        value={user.cpfCNPJ}
                                                        onChange={modifyUser}
                                                        placeholder={"Digitar apenas números"}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="inscEstRG">Inscrição Estadual</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="inscEstRG"
                                                        value={user.inscEstRG}
                                                        onChange={modifyUser}
                                                        placeholder={"Deixar em branco caso seja isento"}
                                                    />
                                                </div>
                                                <div className="col-md mb-3">
                                                    <label htmlFor="contato">Contato</label>
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        name="contato"
                                                        value={user.contato}
                                                        onChange={modifyUser}
                                                        placeholder={"Informe o nome da pessoa para contato direto"}
                                                        required={(user.tipoPessoa === "PJ") ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                )}

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Endereço</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="endereco"
                                            value={user.endereco}
                                            onChange={modifyUser}
                                            placeholder={"Informe seu endereço completo: Rua, Nº e Complemento"}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Bairro</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="bairro"
                                            value={user.bairro}
                                            onChange={modifyUser}
                                            required
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">Cidade</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="cidade"
                                            value={user.cidade}
                                            onChange={modifyUser}
                                            required
                                        />
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="estados">Estado</label>
                                        <select
                                            className="custom-select d-block w-100"
                                            name="estado"
                                            value={user.estado}
                                            onChange={modifyUser}
                                            required
                                        >
                                            <option>Selecionar estado...</option>
                                            {(estados && estados.length>0) ?  
                                                estados.sort((a, b) => a.nome > b.nome ? 1 : -1).map(uf => (
                                                    <option key={uf._id} value={uf._id}> 
                                                        {uf.nome} 
                                                    </option>
                                                ))
                                            : null}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="contato">CEP</label>
                                        <NumberFormat 
                                            format="#####-###" 
                                            className="form-control"
                                            name="cep"
                                            value={user.cep}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="telefone">Telefone</label>
                                        <NumberFormat 
                                            format="(##) #####-####" 
                                            className="form-control"
                                            name="telefone"
                                            value={user.telefone}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="whatsapp">Whatsapp</label>
                                        <NumberFormat 
                                            format="(##) #####-####" 
                                            className="form-control"
                                            name="whatsapp"
                                            value={user.whatsapp}
                                            onChange={modifyUser}
                                            placeholder={"Digitar apenas números"}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col mb-3">
                                        <label htmlFor="new_password">Senha</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={changePassword.password}
                                            onChange={modifyPassword}
                                            autoComplete="password"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="pass_confirmation">Confirmação da senha</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pass_confirmation"
                                            value={changePassword.pass_confirmation}
                                            onChange={modifyPassword}
                                            autoComplete="pass_confirmation"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {(changePassword.password !== '' && changePassword.pass_confirmation !== '')
                                            ? ((changePassword.password === changePassword.pass_confirmation)
                                                ? (<span style={{
                                                    width: '100%',
                                                    marginTop: '.25rem',
                                                    fontSize: '80%',
                                                    color: '#29a73e'
                                                }}>
                                                    As senhas coincidem
                                                    </span>)
                                                : (<span style={{
                                                    width: '100%',
                                                    marginTop: '.25rem',
                                                    fontSize: '80%',
                                                    color: '#dc3545'
                                                }}>
                                                    As senhas devem coincidir
                                                    </span>))
                                            : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="btn-save-user"></label>
                                        <button
                                            type="submit"
                                            disabled={(changePassword.password === '' 
                                                || changePassword.pass_confirmation === '' 
                                                || changePassword.password !== changePassword.pass_confirmation)
                                            }
                                            id="btn-save-user"
                                            className="btn btn-primary btn-lg btn-block"
                                        >Cadastrar</button>
                                    </div>
                                </div>                                

                            </form>

                        </div>

                    </div>

                </div>

                {(!p.admin) 
                    ? <footer className="my-5 pt-5 text-muted text-center text-small">
                        <p className="mb-1">© 2020 Oxedev</p>
                      </footer>
                    : null
                }

            </div>


        </div>
    );
}

export default Cadastrar;