import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NumberFormat from 'react-number-format';

import PaypalButtons from "./PaypalButton";

import api from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    maxWidth: "100%",
    minHeight: 250,
    marginTop: 30
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  title: {
    color: '#007ea7',
    fontWeight:'bold',
  },
  subHeader: {
    color: '#00A8E8'
  },
  cardHeader: {
    backgroundColor: '#00345924'
  },
  cardActions: {
    height: 40,
    padding: 10
  },
  aguardando: {
    backgroundColor: '#fffc96'
  },
  confirmada: {
    backgroundColor: '#96ff9d'
  },
  cancelada: {
    backgroundColor: '#ff9696'
  },
  collapse: {
    backgroundColor: '#f5f5f5'
  },
  debito: {
    color: 'red'
  },
  credito: {
    color: 'green'
  },
  confirmar: {
    color: 'white',
    backgroundColor: 'green'
  },
  cancelar: {
    color: 'white',
    backgroundColor: 'red'
  },
}));

const listStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    overflow: 'auto',
    marginTop: 5,
  },
  ul: {
    padding: 0,
  },
  li: {
    padding: 0,
  },
}));

const CartaoPermuta = (p) => {
  const classes = useStyles();
  const listClasses = listStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);

  const handleClosePaypal = () => {
      setOpenPaypal(false);
  };

  async function handleOpenPaypal() {
      setOpenPaypal(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function pagarComissao(idPgto, meioPgto, dataPgto) {

      handleClosePaypal();

      p.permuta.comissaoPaga = true;
      p.permuta.confirmada = true;
      p.permuta.dataPgtoComissao = dataPgto;
      p.permuta.meioPgtoComissao = meioPgto;
      p.permuta.idPgtoComissao = idPgto;

      const response = await api.post('/Permuta/alterar', p.permuta);

      if (response.status === 200) {
          p.notify('success', `Comissão paga com sucesso!`, 2);
          p.history.push('/Usuario/Permutas');
      } else {
          p.notify('danger', `Erro ao reconhecer pagamento, favor entrar em contato com a administração da Escambo.`, 5);
      }
  }

  async function atualizarPermuta(acao) {

      if (acao === 'CANCELAR') {
        p.permuta.cancelada = true 
        p.permuta.confirmada = false
      } else {
        if (p.user.saldo < p.permuta.valor) {
          p.notify('danger', 'Você não possui saldo suficiente para realizar essa permuta.', 2);
          return;
        } else {
          p.permuta.confirmada = true;
        }
      }

      const response = await api.post('/Permuta/alterar', p.permuta);

      if (response.status === 200) {
          p.notify('success', `Permuta ${(acao==='CANCELAR') ? 'CANCELADA' : 'CONFIRMADA'} com sucesso!`, 2);
          p.history.push('/Usuario/Permutas');
      } else {
          p.notify('danger', `Erro ${(acao==='CANCELAR') ? 'no CANCELAMENTO' : ' na CONFIRMAÇÃO'} da permuta.`, 2);
      }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ 
          root: classes.cardHeader,
          title: classes.title,
          subheader: classes.subHeader
        }}
        title={(p.permuta.cliente._id === p.user._id) 
                  ? `Fornecedor: ${p.permuta.fornecedor ? p.permuta.fornecedor.nome : ''}`
                  : `Cliente: ${p.permuta.cliente.nome}`}
        subheader={new Intl.DateTimeFormat("pt-BR", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit"
                    }).format(new Date(p.permuta.createdAt))
                  }
      />
      <CardContent>
        <Typography 
          gutterBottom 
          variant="h2" 
          component="h2" 
          className={(p.permuta.cliente._id === p.user._id) ? classes.debito : classes.credito}
        >
          <NumberFormat 
              displayType={'text'} 
              name="valorPermuta"
              thousandSeparator={'.'} 
              decimalSeparator={','}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'R$ '} 
              value={parseFloat(p.permuta.valor)}
          />         
        </Typography>
      </CardContent>
      <CardActions 
        disableSpacing 
        className={`${classes.cardActions} 
                    ${(p.permuta.confirmada && p.permuta.comissaoPaga) 
                      ? classes.confirmada 
                      : (p.permuta.cancelada)
                        ? classes.cancelada 
                        : classes.aguardando }`}>

          {(p.permuta.confirmada && p.permuta.comissaoPaga) 
              ? (<Typography> CONFIRMADA </Typography>)
              : (p.permuta.cancelada)
                ? (<Typography> CANCELADA </Typography>)
                : (p.permuta.fornecedor._id === p.user._id && !p.permuta.comissaoPaga) 
                  ?  (<div>
                        <Button 
                          variant="contained" 
                          className={classes.confirmar}
                          onClick={() => handleOpenPaypal()}
                        >
                          PAGAR COMISSÃO
                        </Button>
                        <Button 
                          variant="contained" 
                          color="secondary" 
                          className={`${classes.cancelar} ml-3`}
                          onClick={() => atualizarPermuta("CANCELAR")}
                        >
                          CANCELAR
                        </Button>
                      </div>)
                  : (p.permuta.cliente._id === p.user._id && !p.permuta.confirmada) 
                    ?  (<div>
                          <Button 
                            variant="contained" 
                            className={classes.confirmar}
                            onClick={() => atualizarPermuta("CONFIRMAR")}
                          >
                            CONFIRMAR
                          </Button>
                          <Button 
                            variant="contained" 
                            color="secondary" 
                            className={`${classes.cancelar} ml-3`}
                            onClick={() => atualizarPermuta("CANCELAR")}
                          >
                            CANCELAR
                          </Button>
                        </div>)
                    : (<Typography> AGUARDANDO CONFIRMAÇÃO DA OUTRA PARTE </Typography>)}
        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
        <CardContent>

          <Typography paragraph>ID: {p.permuta.id}</Typography>
          
          <div className="row">
            <div className="col-12">
              <h6 className="main-title mb-1">Produtos:</h6>
              <List dense className={listClasses.root}>
                <li>
                  <ul className={listClasses.ul}>
                    {p.permuta.produtos.map((produto, index) => (
                      <ListItem key={index} className={listClasses.li}>
                        <FiberManualRecordIcon 
                          style={{"height":"5px"}}
                        />
                        <ListItemText 
                          primary={`${produto.nome} - R$ ${parseFloat(produto.valor).toFixed(2)}`} 
                        />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              </List>
            </div>
          </div>

          <Typography paragraph>Obervações: {p.permuta.observacoes}</Typography>

        </CardContent>
      </Collapse>

      <Dialog 
        open={openPaypal} 
        onClose={handleClosePaypal} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <PaypalButtons
          permuta={p.permuta}
          onApprove={pagarComissao}
        />
      </Dialog>
    </Card>
  );
}

export default CartaoPermuta;