import React, { useEffect, useState, useContext } from 'react';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';

import './Pagar.css';
import PaypalButtons from "../layout/PaypalButton";
import EscamboContext from '../EscamboContext';

import api from '../services/api';

import logo from '../assets/logo.svg';

const Pagar = (p) => {
  const [valor, setValor] = useState();
  const [aPagar, setAPagar] = useState();
  const [utilizarSaldo, setUtilizarSaldo] = useState(false);
  const [openPaypal, setOpenPaypal] = useState(false);
  const [fornecedor, setFornecedor] = useState({});
  const { loggedUser: user } = useContext(EscamboContext);

  const handleClosePaypal = () => {
    setOpenPaypal(false);
  };

  async function switchUtilizarSaldo(e) {
    setUtilizarSaldo(e.target.checked);
  }

  useEffect(() => {

    let vPagar = 0;
    let valorCompra = valor || 0;

    if (user && utilizarSaldo) {
      user.saldo >= valorCompra
        ? vPagar = 0
        : vPagar = valorCompra - (user.saldo || 0);
    } else {
      vPagar = valorCompra;
    }

    setAPagar(vPagar);

  }, [valor, utilizarSaldo, user]);

  async function getFornecedor(cpfCnpj) {
    const response = await api.get(`/Usuario/findByCPFCNPJ/${cpfCnpj}`);
    if (response.status === 200) {
      setFornecedor(response.data);
    }
  }

  async function getEscambo() {
    const response = await api.get('/Usuario/findByCPFCNPJ/03625840000138');
    if (response.status === 200) {
      return response.data._id
    }
    return null
  }

  useEffect(() => {

    getFornecedor(p.match.params.fornec);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifyValor = e => {
    let valor = parseFloat(e.target.value.replace('R$ ', '').replace('.', '').replace(',', '.'));
    setValor(valor);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  async function transferirCredito() {
    if (parseFloat(valor-aPagar)<=0) return;
    if (!user) return;

    const permuta = {};
    permuta.fornecedor = fornecedor._id;
    permuta.cliente = user._id;
    permuta.confirmada = true;
    permuta.valor = parseFloat(valor-aPagar);

    const response = await api.post('/Permuta/cadastrar', { permuta }).then((response) => {
      if (response.status === 200) {
        p.app.notify('success', 'Permuta realizada com sucesso! Confira na sua página de permutas.', 2);
        return true
      } else {
        p.app.notify('danger', response.data, 2);
        return false
      }
    }).catch((err) => {
      console.error(err);
      if (err.response.data) { p.app.notify('danger', err.response.data, 2); };
      return false;
    });

    return response;
  }

  async function transferirCreditoPaypal(idPgto) {
    if (!idPgto) return true;
    const escamboId = await getEscambo();
    const permuta = {};

    permuta.id = idPgto;
    permuta.fornecedor = fornecedor._id;
    permuta.cliente = escamboId;
    permuta.confirmada = true;
    permuta.valor = parseFloat(aPagar);

    const response = await api.post('/Permuta/paypal', { permuta }).then((response) => {
      if (!response || response.status !== 200) {
        p.app.notify('danger', response.data, 2);
        return null;
      }
      p.app.notify('success', 'Compra realizada com sucesso!', 2);
      return response.data;
    }).catch((err) => {
      console.error(err);
      if (err.response.data) p.app.notify('danger', err.response.data, 2);
      return null;
    });

    return response;
  }

  async function handleApproval(idPgto = undefined) {
    const pgtoPaypal = await transferirCreditoPaypal(idPgto);
    handleClosePaypal();

    if (user && (pgtoPaypal || !idPgto)) {
      const creditoTransferido = await transferirCredito();
      if (creditoTransferido) p.history.push('/Usuario/Permutas');
    } else {
      if (idPgto && pgtoPaypal) {
        p.history.push({
          pathname: `/comprovante/${pgtoPaypal._id}`,
          state: { permuta: pgtoPaypal },
        });
      }
    }
  }

  async function handleOpenPaypal() {
    if (aPagar > 0) {
      setOpenPaypal(true);
      return;
    }
    if (user) {
      handleApproval()
    }
  };

  return (
    <div className="login-container">

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3">
            <img src={logo} alt='Escambo' />
          </div>

          <div className="col-lg-6 offset-lg-3 mt-5">
            <form onSubmit={handleSubmit}>

              <div className="col">
                <Typography name="lblCLiente" variant="h6">
                  Cliente
                </Typography>
                <Typography name="lblValor" variant="h5" style={{ color: '#00A8E8' }}>
                  {(fornecedor) ? fornecedor.nome : 'CPF/CNPJ inválido'}
                </Typography>
              </div>

              <div className="col mt-2">
                <Typography name="lblValor" variant="h6">
                  Informe o Valor da Compra
                </Typography>
                <NumberFormat
                  className="form-control"
                  displayType={'input'}
                  name="valor"
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  prefix={'R$ '}
                  value={valor}
                  onChange={modifyValor}
                />
              </div>

              <div className="col mt-2">
                <Typography name="lblValor" variant="h6">
                  Utilizar Saldo?
                </Typography>
                <Switch
                  disabled={!user || !user.saldo || user.saldo <= 0}
                  checked={utilizarSaldo}
                  onChange={switchUtilizarSaldo}
                  color="primary"
                />
                <NumberFormat
                  displayType={'text'}
                  name="saldo"
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '}
                  value={user ? user.saldo : '0'}
                  style={{ fontSize: '1.5rem', color: '#00A8E8' }}
                />
                {!user && (
                  <Typography
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      p.app.alterState('whereTo', p.location.pathname);
                      p.history.push("/");
                    }}
                  >
                    Faça login para obter seu saldo
                  </Typography>
                )}
              </div>

              <div className="col mt-2">
                <Typography name="lblValor" variant="h6">
                  Valor a Pagar
                </Typography>
                <NumberFormat
                  displayType={'text'}
                  name="saldo"
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '}
                  value={aPagar || 0}
                  style={{ fontSize: '1.5rem', color: '#00A8E8' }}
                />
              </div>

              <Button
                variant="contained"
                onClick={() => handleOpenPaypal()}
              >
                CONTINUAR
              </Button>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        open={openPaypal}
        onClose={handleClosePaypal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <PaypalButtons
          fornecedor={fornecedor}
          valor={aPagar ? parseFloat(aPagar).toFixed(2) : 0}
          onApprove={handleApproval}
        />
      </Dialog>

    </div>
  );
}

export default Pagar;