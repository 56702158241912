import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    maxWidth: "100%",
    minHeight: 350,
    marginTop: 30
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  title: {
    color: '#007ea7',
    fontWeight:'bold',
  },
  subHeader: {
    color: '#00A8E8'
  },
  cardHeader: {
    backgroundColor: '#00345924'
  },
  cardActions: {
    height: 40,
    padding: 0
  },
  collapse: {
    backgroundColor: '#f5f5f5'
  }
}));

const listStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 175,
    marginTop: 5,
  },
  ul: {
    padding: 0,
  },
  li: {
    padding: 0,
  },
  match: {
    color: '#00A8E8'
  },
}));

const CartaoParceiro = (p) => {
  const classes = useStyles();
  const listClasses = listStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ 
          root: classes.cardHeader,
          title: classes.title,
          subheader: classes.subHeader
        }}
        title={p.parceiro.nome}
        subheader={p.parceiro.razaoSocial}
      />
      <CardContent>
        <div className="row">
          <div className="col-12 col-lg-6">
            <h6 className="main-title mb-1">Oferece:</h6>
            <List dense className={listClasses.root}>
              <li>
                <ul className={listClasses.ul}>
                  {p.parceiro.oferece.map((produto) => (
                    <ListItem key={produto._id} className={listClasses.li}>
                      <FiberManualRecordIcon 
                        style={{"height":"5px"}}
                      />
                      <ListItemText 
                        primary={produto.nome} 
                        className={(produto.nome === p.nomeBuscado) ? listClasses.match : ''}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            </List>
          </div>
          <div className="col">
            <h6 className="main-title mb-1">Deseja:</h6>
            <List dense className={listClasses.root}>
              <li>
                <ul className={listClasses.ul}>
                  {p.parceiro.deseja.map((produto) => (
                    <ListItem key={produto._id} className={listClasses.li}>
                      <FiberManualRecordIcon 
                        style={{"height":"5px"}}
                      />
                      <ListItemText 
                        primary={produto.nome} 
                        className={(produto.nome === p.nomeBuscado) ? listClasses.match : ''}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            </List>
          </div>
        </div>
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <IconButton aria-label="share">
          <a target={"_blank"} rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=55${p.parceiro.whatsapp}`}>
            <WhatsAppIcon style={{"color":"#25D366"}}/>
          </a>
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
        <CardContent>
          {(p.parceiro.tipoPessoa === 'PJ')
            ? <Typography paragraph>CNPJ: <NumberFormat value={p.parceiro.cpfCNPJ} displayType={'text'} format="##.###.###/####-##"/></Typography>
            : <Typography paragraph>CPF: <NumberFormat value={p.parceiro.cpfCNPJ} displayType={'text'} format="###.###.###-##"/></Typography>
          }
          <Typography paragraph>Telefone: <NumberFormat value={p.parceiro.telefone} displayType={'text'} format="(##) #####-####"/></Typography>
          <Typography paragraph>E-mail: {p.parceiro.email}</Typography>
          <Typography paragraph>Procurar por: {p.parceiro.contato}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default CartaoParceiro;