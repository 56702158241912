import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Spinner from "./Spinner";

const CLIENT = {
  sandbox:"Afsq-XYQaHciIgCa5qMjfiSecaz-vNXHFqT-FcbFw4csNd69CYu15hJe9cHItnG_LAPRNjvqXPE3D0GC",
  production:"ASvC5xwqTnu7K6R6SM-8D_pI60VHdrSNOcU6UbU_ZdOFo96VqVAZp8RAsyV-FwyDApyDgCXOrzPNdtST"
};

const CLIENT_ID = CLIENT.production;

let PayPalButton = null;
class PaypalButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = (data, actions) => {
    return actions.order.create({
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      },
      purchase_units: [
        (this.props.permuta) ?
          {
            description: +`escambo.app: comissão da permuta ${this.props.permuta.id}`,
            amount: {
              currency_code: "BRL",
              value: this.props.permuta.comissao
            }
          }
        : {
            description: +`escambo.app: compra em ${this.props.fornecedor.nome}`,
            amount: {
              currency_code: "BRL",
              value: this.props.valor
            }
          }
      ]
    });
  };

  onApprove = (data, actions) => {

    this.setState({ loading: true });
    
    actions.order.capture().then(details => {
      this.props.onApprove(details.id, "PAYPAL", details.create_time);
    });
  };

  render() {
    const { showButtons, loading } = this.state;
    const { permuta, fornecedor, valor } = this.props;

    return (
      <div className="main-container">
        {loading && <Spinner />}

        {showButtons && (
          <div>
            {(permuta) ?
              <div>
                <h4>Comissão referente a permuta <b>{permuta.id}</b></h4>
                <h2>Valor: <b>R$ {permuta.comissao}</b></h2>
              </div>
            : <div>
                <h4>Pagamento referente a compra em <b>{fornecedor.nome}</b></h4>
                <h2>Valor: <b>R$ {valor}</b></h2>
              </div>
            }

            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&enable-funding=mercadopago&locale=pt_BR&currency=BRL`)(PaypalButton);