import React from 'react';

import './Menu.css';

import { FaUserAlt, FaHandshake } from "react-icons/fa";
import { GiExitDoor, GiWallet } from "react-icons/gi";
import logo from '../assets/min-logo.svg';

class Menu extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            usuariosPath: "",
            permPath: "",
        }

        this.logout = this.logout.bind(this);
        this.goToUsuarios = this.goToUsuarios.bind(this);
        this.goToPermutas = this.goToPermutas.bind(this);
        this.updatePath = this.updatePath.bind(this);
    }

    componentDidMount(){

        const pathname = this.props.history.location.pathname;

        if (pathname.includes('/Admin')) {
            this.updatePath('usuariosPath');
        } 

        if (pathname.includes('/Admin/Usuarios')) {
            this.updatePath('usuariosPath');
        } 
        
        if (pathname.includes('/Admin/Permutas')) {
            this.updatePath('permPath');
        } 
    }

    logout() {

        this.props.app.removeCookie('loggedUser');
        this.props.history.push('/');
        window.location.reload();
    }

    updatePath(path) {

        this.setState({
            usuariosPath: "",
            permPath: "",
            extrPath: "",
        });

        this.setState({
            [path]: "active"
        })
    }
    
    goToUsuarios() {
        this.props.history.push('/Admin/Usuarios');
        this.updatePath('usuariosPath');
    }
    
    goToPermutas() {
        this.props.history.push('/Admin/Permutas');
        this.updatePath('permPath');
    }
    
    goToExtratos() {
        this.props.history.push('/Admin/Extratos');
        this.updatePath('extrPath');
    }

    render() {
        return (
            <div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-light">
                    <a className="navbar-brand" href="/Usuario/Parceiros">
                        <div className="menu-icons mt-2"><img src={logo} alt='Escambo' className="logo"/></div>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#textoNavbar" aria-controls="textoNavbar" aria-expanded="false" aria-label="Alterna navegação">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="textoNavbar">
                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                            <div className={`menu-icons ${this.state.usuariosPath}`}>
                                <button onClick={() => {this.goToUsuarios()}}>
                                    <FaUserAlt className="menu-icon"/> Usuarios
                                </button>
                            </div>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                            <div className={`menu-icons ${this.state.permPath}`}>
                                <button onClick={() => {this.goToPermutas()}}>
                                    <FaHandshake className="menu-icon"/> Permutas
                                </button>
                            </div>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target="#textoNavbar">
                            <div className={`menu-icons ${this.state.extrPath}`}>
                                <button onClick={() => {this.goToExtratos()}}>
                                    <GiWallet className="menu-icon"/> Extratos
                                </button>
                            </div>
                        </li>
                        </ul>
                         <span className="navbar-text">
                            <div className="menu-icons">
                                <button onClick={() => {this.logout()}}>
                                    <GiExitDoor className="menu-icon"/> Sair
                                </button>
                            </div>
                        </span>
                    </div>
                </nav>                          
            </div>
        );
    }
}


export default Menu;