import React, { useEffect, useState, useContext} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ReplayIcon from '@material-ui/icons/Replay';

import CartaoPermuta from '../../layout/CartaoPermuta';

import api from '../../services/api';
import EscamboContext from '../../EscamboContext';

const Permuta = (p) => {

    const {loggedUser: user} = useContext(EscamboContext);
    const [permutas, setPermutas] = useState([]);

    //buscar permutas
    async function loadPermutas() {

        const response = await api.get('/Permuta/listar');
        setPermutas(response.data);
    }

    //atualizar saldo
    async function atualizarSaldo() {

        const id = user._id;

        api.get(`/Usuario/find/${id}`).then((response, err) => {

            if (response.status === 200) {
                   
                p.app.setCookie('loggedUser', response.data);        
            } else {
                const error = new Error(response.error);
                throw error;
            }

        }).catch((err) => {
            console.error(err);
        });
    }

    //carregar usuario
    useEffect(() => {

        if (!user) {
            p.history.push('/');
        };
    });

    //carregar permutas
    useEffect(() => {

        if (user._id !== '') {
          loadPermutas();
          atualizarSaldo();
        }

    // eslint-disable-next-line
    }, [user._id]);

    return (
        <div>

            <div className="main-wide-container" >

                <div className="wide-container">
                    
                    <div className="row">
                        <div className="col-1 mb-3" style={{textAlign: "center"}}>
                            <h6 className="main-title mb-1">Saldo</h6>
                            <Chip 
                                color="primary" 
                                avatar={<Avatar>R$</Avatar>} 
                                label={(user.saldo) ? (Math.round(user.saldo * 100) / 100).toFixed(2) : 0.00}
                                deleteIcon={<ReplayIcon />} 
                                onDelete={atualizarSaldo}
                            /> 
                        </div>
                    </div>

                    { permutas.filter(p => (!p.confirmada || !p.comissaoPaga) && !p.cancelada).length > 0 ? 
                        <div>
                            <div className="row">
                                <div className="col-md mb-3" style={{textAlign: "center"}}>
                                    <h2 className="main-title mb-1">Permutas Pendentes</h2>
                                </div>
                            </div>

                            <div className="row">
                                {permutas.map(permuta => 
                                    (permuta.cliente && (!permuta.confirmada || !permuta.comissaoPaga) && !permuta.cancelada) 
                                    ?
                                        <div className="col-12 col-lg-6 col-xl-4" key={permuta._id}>
                                            <CartaoPermuta
                                                permuta={permuta}
                                                user={user}
                                                notify={p.app.notify}
                                                history={p.history}
                                            />
                                        </div>
                                    :   null
                                )}
                            </div>
                        </div>
                        : null
                    }      

                    <div className="row">
                        <div className="col-md mb-3 mt-5" style={{textAlign: "center"}}>
                            <h2 className="main-title mb-1">Permutas Realizadas</h2>
                        </div>
                    </div>

                    <div className="row">
                        { permutas.filter(p => p.confirmada && p.comissaoPaga).length > 0 ? (
                                permutas.map(permuta => (
                                    (permuta.cliente && ((permuta.confirmada && permuta.comissaoPaga) || permuta.cancelada )) ?
                                    <div className="col-12 col-lg-6 col-xl-4" key={permuta._id}>
                                        <CartaoPermuta
                                            permuta={permuta}
                                            user={user}
                                            notify={p.app.notify}
                                            history={p.history}
                                        />
                                    </div>
                                    : null
                                ))        
                        ) : <div className="col-md mb-3" style={{textAlign: "center"}}>
                                Não há permutas realizadas ainda.
                            </div> 
                        }      
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Permuta;